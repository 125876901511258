<template>
  <div v-if="showForm">
    <b-card>
      <b-row>
        <b-col class="col-4">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.member_name"
                :text="avatarText(data.member_name)"
                variant="primary"
                class="d-none d-lg-block"
              />
            </template>
            <h5 class="m-0">
              {{ data.member_name }}
            </h5>
            <template v-if="data.member_birthdate">
              <small>{{ formatDateBR(data.member_birthdate) }} - {{ data.member_age }} anos</small>
            </template>
          </b-media>
        </b-col>
        <b-col class="col">
          <h5 class="mb-0">
            Condições de saúde informadas pelo paciente
          </h5>
          <small>
            {{ formatString(optionsCare) }}
          </small>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <bariatric-patient />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BAvatar, BMedia, BRow,
} from 'bootstrap-vue'
import avatarText from '@/utils/filter'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import bariatricPatient from '@/views/pages/bariatric/Bariatric-patient.vue'

export default {
  components: {
    BCard,
    BCol,
    BAvatar,
    BMedia,
    BRow,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    bariatricPatient,

  },

  data() {
    return {
      data: this.$store.getters['bariatric/bariatricMember'],
      patient: '',
      optionsCare: '',
      showForm: false,

      avatarText,
    }
  },

  created() {
    this.$store.commit('protocolModel/UPDATE_ACTION', 'register')
    this.listCare()
  },

  methods: {
    listCare() {
      this.showForm = false
      this.$store.dispatch('patient/listCare')
        .then(response => {
          this.optionsCare = response.data.data.member_cares_info
          this.showForm = true
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    },
    formatString(value) {
      if (value !== undefined && value !== null) {
        const lengthArray = value.length
        let string = ''

        if (lengthArray > 0) {
          value.forEach((valueArray, index) => {
            string += valueArray.name
            if (index < lengthArray - 2) {
              string += ', '
            } else if (index === lengthArray - 2) {
              string += ' e '
            }
          })
        } else {
          string = '-'
        }
        return string
      }
      return ''
    },
  },
}
</script>
